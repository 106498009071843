

#contacts {
    position: relative;
    clear: both;


    .contacts-wrapper {
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        padding: 50px 50px;
    }

    .contacts-separator {
        margin-top: 34px;
        margin-bottom: 43px;
    }

    ul {

        display: flex;
        align-items: center;
        justify-content: space-between;
       
        li {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 45px;

            cursor: pointer;

            text-decoration-line: underline;

            color: #000000;
        }
    }
}

@media screen and (max-width: 1024px) {
    #contacts {
        .contacts-wrapper {
            padding: 0px 20px 30px;
        }

        ul {
            li {
                font-size: 25px;
            }
        }
    }
}

@media screen and (max-width: 640px) {
    #contacts {
        .contacts-wrapper {
            padding: 0px 10px 30px;
        }

        .contacts-separator {
            margin-top: 10px;
            margin-bottom: 20px;
        }

        ul {
            li {
                font-size: 18px;
            }
        }
    }
}