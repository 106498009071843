.nav-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;

    z-index: 99;

    &.fixed {
        background: linear-gradient(180deg, white, transparent);
        backdrop-filter: blur(3px);
    }

   

    .nav-bar-wrapper {
        max-width: 1500px;
        padding: 37px 50px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    ul {
        display: flex;

        li {
            margin-left: 67px;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            user-select: none;
        }

        a {
            transition: all .3s ease;
            cursor: pointer;
        }

        a.active {
            color: #a0a0a0;
        }

        a:hover {
            color: #a0a0a0;
        }
    }

    

    .nav-bar-logo {
        z-index: 10000;
        span {
            cursor: pointer;
        }
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            span:last-child {
                margin-left: 5px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .nav-bar {
        .nav-bar-wrapper {
            padding: 30px 20px;
        }

        ul {
            li {
                margin-left: 35px;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .nav-bar {

        &.fixed {
            background: transparent;
            backdrop-filter: none;
        }

        .nav-bar-wrapper {
            padding: 15px 10px;
        }

        ul {
            display: none;
        }
    }
}