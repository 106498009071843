
.works-item-page {
    .wide-image {
        width: 100%;
        display: block;
    }

    .padding-top-m {
        padding-top: 75px;
    }

    &.cute-characters {
        img {
            width: 100%;
            padding: 10px;
        }
    }

    

    .avocado-group {
        min-height: 700px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        .group-avocado-1 {
            max-width: 657px;
            width: 100%;
            padding: 10px;
        }

        .group-avocado-2 {
            max-width: 377px;
            width: 100%;
            position: relative;
            top: -47px; 
            padding: 10px;
        }

        .group-avocado-3 {
            max-width: 277px;
            width: 100%;
            position: relative;
            top: -157px;
            padding: 10px;
        }
        
    }

    .marshmallow-group {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        align-content: stretch;
        div {
            display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
        }
        
    }

    .mushroom-group {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }
}


// @media screen and (max-width: 1200px) {
//     .works-item-page {
//         .avocado-group {
//             .group-avocado-1 {
//                 max-width: 556px;
//             }

//             .group-avocado-2 {
//                 max-width: 317px;
//             }
//         }
//     }
// }

@media screen and (max-width: 768px) {
    .works-item-page {
        .avocado-group {
            min-height: 300px;
        }
    }
}