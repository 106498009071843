

.playables-ads-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .playable-container {
        margin-bottom: 30px;
        position: relative;

        height: 500px;
        width: 248;

        .playable-container-hover {
            position: absolute;
            top: 0; left: 0;
            right: 0; bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #ffffff8f;
            align-content: center;
            flex-wrap: wrap;

            transition: all .2s ease-in-out;
            opacity: 0;

            div {
                width: 100%;
                text-align: center;
            }

            img {
                display: block;
            }
        }

        &:hover {
            .playable-container-hover {
                opacity: 1;
            }
        }
    }
    
}