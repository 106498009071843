.playable-container {
    margin-bottom: 30px;
    position: relative;

    cursor: pointer;

    height: 500px;
    width: 248px;

    .playable-hover-container {
        position: absolute;
        top: 0; left: 0;
        right: 0; bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff8f;
        align-content: center;
        flex-wrap: wrap;

        transition: all .2s ease-in-out;
        opacity: 0;

        span {
            width: 100%;
            text-align: center;
        }

        > img {
            display: block;
        }
    }


    &:hover {
        .playable-hover-container {
            opacity: 1;
        }
    }
}