

.night-castle-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: stretch;
    img {
        padding-top: 45px;
    }
}