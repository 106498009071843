.works-section {
    width: 100%;

    .works-list {

        .works-list-info {
            padding-left: 40px;

            &.right {
                padding-left: 0;
                padding-right: 40px;
            }
        }

        span {
            display: block;
        }

        .list-info-title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 21px;

            transition: all .3s ease-in-out;
        }

        .list-info-type {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            /* identical to box height */

            margin-bottom: 21px;
            color: rgba(0, 0, 0, 0.5);
        }

        .list-info-description {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 45px;
        }
    }

    .works-list-image-hover {
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        justify-content: center;
        align-items: center;

        background: #00000059;

        color: #ffffff;
        font-size: 25px;
        user-select: none;

        cursor: pointer;

        opacity: 0;

        transition: all .3s ease-in-out;
    }

    .works-list-image {
        position: relative;

        width: 100%;
        width: 813px;
        height: 451px;

        img {
            transition: all .3s ease-in-out;
        }
        

        &:hover {
            
            img {
                filter: blur(10px);
                transform: translate3d(0, 0, 0);
            }
            .works-list-image-hover {
                opacity: 1;
            }
        }
    }

    .works-list-image:hover + .works-list-info {
        .list-info-title {
            color: #a0a0a0;
        }
    }

     .works-list-image:hover + .works-list-info.right {
        .list-info-title {
            color: #a0a0a0;
        }
    }

    .list-info-title:hover {
        color: #a0a0a0;
    }

    .works-list li{
        display: flex;
        align-items: flex-start;
        justify-content: center;

        margin-bottom: 160px;
    }
    

}