

.game-env-group {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    img {
        margin-bottom: 30px;
    }
    
}