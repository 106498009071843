.bm-menu-wrap {
    position: fixed;
    right: 0px;
    z-index: 1100;
    width: 100% !important;
    height: 100%;
    transition: all 0.5s ease 0s;
    top: 0;
    bottom: 0;
    padding: 15px;
    background: #FCF1E5;
}

.bm-burger-button {
    width: 30px;
    position: relative;
    height: 15px;
}

.bm-item-list {
    padding-left: 5%;
    padding-top: 12%;
}
.bm-item.active {
    color: #8a8a8a;
}
.bm-burger-button {
    span {
        width: 30px;
        display: block;
        height: 50px;

        &.bm-burger-bars {
            height: 4px !important;
            background-color: #000;
            

            &:nth-child(2) {
                display: none;
            }
        }

        
    }
}

.bm-cross-button {
    .bm-cross {
        background: #000;
        height: 30px !important;
    }
}

.bm-menu {
    a {
        font-size: 30px;
        margin-bottom: 30px;
    }
}