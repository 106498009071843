.about-section {
    width: 100%;
    margin-bottom: 170px;

    position: relative;

    .canvas-about-container {
        overflow: hidden;
    }

    .about-description {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;

        margin-top: 55px;
        max-width: 1083px;

        color: #000000;

        margin-bottom: 138px;
    }

    .clients-list-wrapper {
        margin-bottom: 170px;
        margin-top: 80px;
    }

    .techstack-list-wrapper {
        margin-top: 83px;
        margin-bottom: 167px;
    }

    .experience-list-wrapper {
        margin-top: 90px;
        margin-bottom: 58px;
    }
}

@media screen and (max-width: 1200px) {
    .about-section {
        .about-description {
            font-size: 25px;
            line-height: 40px;
            margin-bottom: 120px;
            max-width: 900px;
        }

        .techstack-list-wrapper {
            margin-top: 75px;
            margin-bottom: 120px;
        }

        .clients-list-wrapper {
            margin-bottom: 100px;
            margin-top: 50px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .about-section {
        margin-bottom: 90px;
        .about-description {
            font-size: 20px;
            line-height: 36px;
            margin-top: 55px;
            max-width: 800px;
            margin-bottom: 90px;
        }

        .techstack-list-wrapper {
            margin-top: 35px;
            margin-bottom: 70px;
        }

        .experience-list-wrapper {
            margin-top: 50px;
            margin-bottom: 58px;
        }

        .clients-list-wrapper {
            margin-bottom: 50px;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 640px) {
    .about-section {
        margin-bottom: 50px;
        .about-description {
            font-size: 18px;
            line-height: 30px;
            margin-top: 35px;
            margin-bottom: 90px;
        }

        .blurred-image {
            background-size: 150%;
            top: -300px;
        }

        .canvas-about-container {
           // transform: translate3d(0px, 0px, 0px) scale(2) translateX(-50%) !important;
        }
    }
}

@media screen and (max-width: 480px) {
    .about-section {
        margin-bottom: 0px;
        .about-description {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 60px;
        }

        .techstack-list-wrapper {
            margin-top: 15px;
            margin-bottom: 40px;
        }

        .experience-list-wrapper {
            margin-top: 20px;
            margin-bottom: 58px;
        }
    }
}