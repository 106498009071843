.works-item-page {

    padding-top: 190px;
    padding-bottom: 150px;

    .wrapper {
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        padding: 0 50px;
    }

    // h1 {
    //     font-family: 'Poppins';
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 80px;

    //     margin: 0;
    //     margin-bottom: 10px;
    // }

    h1 {
        margin-bottom: 10px;
    }

    .works-item-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;

        max-width: 900px;

        margin: 50px 0;
    }
}

@media screen and (max-width: 640px) {
    .works-item-page {

        padding-top: 100px;

        .works-item-description {
            font-size: 18px;
            line-height: 30px;
        }

        .wrapper {
            padding: 0 10px;
        }
    }
}