.techstack-list-container {

    display: grid;
    grid-template-columns: repeat(2, 300px);
    grid-gap: 0 75px;
    grid-template-rows: repeat(4, 77px);
    justify-items: start;
    align-content: center;

    .techstack-list-item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .techstack-list-item-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
    }

    span {
        margin-left: 0;
        &:last-child {
            margin-left: 31px;
        }
    }

    .techstack-list-item-icon {
        width: 66px;
        height: 54px;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        $iconNames: ('blender', 'unity', 'mixamo', 'photoshop', 'illustrator', 'after', 'figma');
        @each $icon in $iconNames {
            &.techstack-list-item-icon-#{$icon} {
                background-image: url(../../images/tech-icon-#{$icon}.png);
                
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .techstack-list-container {

        grid-template-rows: repeat(4, 70px);
        grid-gap: 0;

        .techstack-list-item-title {
            font-size: 25px;
        }

        .techstack-list-item-icon {
            width: 48px;
            height: 47px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .techstack-list-container {

        grid-template-rows: repeat(4, 60px);

        .techstack-list-item-title {
            font-size: 20px;
        }

        .techstack-list-item-icon {
            width: 39px;
            height: 38px;
        }
    }
}

@media screen and (max-width: 640px) {
    .techstack-list-container {
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(4, 55px);

        .techstack-list-item-title {
            font-size: 16px;
        }

        .techstack-list-item-icon {
            width: 30px;
            height: 30px;
        }
    }
}

@media screen and (max-width: 480px) {
    .techstack-list-container {

        .techstack-list-item-title {
            font-size: 14px;
        }

        .techstack-list-item-icon {
            width: 25px;
            height: 25px;
        }
    }
}