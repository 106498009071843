.fade-enter {
  opacity: 0;
 
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;

  transition: opacity 250ms ease;
}
