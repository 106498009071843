.playables-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    
}

.playables-load-button {
        
    background: transparent;
    padding: 15px 25px;
    border: 2px solid #000;
    border-radius: 25px;
    cursor: pointer;
    margin: 0 auto;

    font-family: 'Poppins';
    font-size: 15px;

    transition: all .15s ease-in-out;

    &:hover {
        background: #000;
        color: #fff;

        text-decoration: underline;
    }
}