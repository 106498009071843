.process-section {
    width: 100%;

    .process-description {
        max-width: 1083px;
        margin-top: 55px;

        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 45px;
    }

    .process-table-bg {
        background: #F7F7FF;

        margin-top: 55px;
        margin-bottom: 100px;

        padding: 97px 0;

        width: 100%;

        display: flex;
        justify-content: center;
        align-items: stretch;

        ul {
            display: flex;
            align-items: stretch;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            align-content: stretch;
        }

        li {
            border-right: 1px solid;
            position: relative;
            
            max-width: 302px;
            width: 100%;

            padding: 10px 20px 70px 20px;

            &:first-child {
                padding: 10px 20px 70px 0px;

                .process-table-num {
                    left: 0;
                }
            }

            &:last-child {
                border-right: 0px solid;
                margin-right: 0;
                padding: 10px 0px 70px 20px;
            }

            span {
                display: block;
            }
        }        
    }

    .process-table-title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 43px;

        margin-bottom: 25px;
    }

    .process-table-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        margin-bottom: 37px;
        min-height: 200px;
    }

    .process-table-num {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 63px;

        position: absolute;
        bottom: 0;
        left: 20px;
    }
}

@media screen and (max-width: 1200px) {
    .process-section {
        .process-description {
            font-size: 25px;
            line-height: 40px;
            margin-bottom: 120px;
            max-width: 900px;
        }

        .process-table-title {
            font-size: 40px;
        }

        .process-table-description {
            font-size: 22px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .process-section {
        margin-bottom: 90px;
        .process-description {
            font-size: 20px;
            line-height: 36px;
            margin-top: 55px;
            max-width: 800px;
            margin-bottom: 90px;
        }

        .process-table-title {
            font-size: 30px;
        }

        .process-table-description {
            font-size: 18px;
            min-height: 1px;
        }

        .process-table-bg {
            ul {
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
                flex-direction: column;
            }

            li {
                border-right: none;
                padding: 67px 0px 0;
                max-width: 85%;

                .process-table-num {
                    top: 0;
                    left: 0;
                }

                &:first-child {
                    padding: 67px 0px 0;
                    .process-table-num {
                        top: 0;
                    }
                }

                &:last-child {
                    padding: 67px 0px 0;
                }
            }

            .process-table-num {

            }
        }

        
    }
}

@media screen and (max-width: 768px) {
    .process-section {
        margin-bottom: 50px;
        .process-description {
            font-size: 18px;
            line-height: 30px;
            margin-top: 35px;
            margin-bottom: 90px;
        }
    }
}

@media screen and (max-width: 640px) {
    .process-section {
        margin-bottom: 50px;
        .process-description {
            font-size: 18px;
            line-height: 30px;
            margin-top: 35px;
            margin-bottom: 90px;
        }

        .process-table-bg {
            padding: 30px 0;
        }
    }
}

@media screen and (max-width: 480px) {
    .process-section {
        margin-bottom: 0;
        .process-description {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 60px;
        }
    }
}