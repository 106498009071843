.clients-list-container {
    display: grid;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 20%);
    grid-template-rows: repeat(3, 180px);
    // margin-bottom: 170px;
    // margin-top: 80px;

    width: 100%;
        

    .clients-list-item {
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        width: 100%;
        height: 136px;
        @for $i from 1 through 12 {
            @if $i != 11 {
                &.clients-list-item-#{$i} {
                    background-image: url(../../images/client-logo-#{$i}.png);
                }
            } @else {
                &.clients-list-item-#{$i} {
                    // background-image: url(../../images/client-logo-#{$i}.png);
                    display: none;
                }
            }
            
        }
    }
}

@media screen and (max-width: 1024px) {
    .clients-list-container {
        grid-template-rows: repeat(3, 160px);
        margin: 0 auto;
    }
}

@media screen and (max-width: 768px) {
    .clients-list-container {
        grid-template-rows: repeat(3, 120px);
    }
}

@media screen and (max-width: 480px) {
    .clients-list-container {
        grid-template-rows: repeat(3, 70px);
    }
}