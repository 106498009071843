.experience-list-container {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 38px;

    .experience-list-line {
        // margin-top: 90px;
        // margin-bottom: 58px;
    }

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        min-height: 180px;
    }

    .experience-list-item-arrow {
        max-width: 100px;
    }

    .experience-list-item-years {
        display: block;
        text-align: center;
        max-width: 150px;
        width: 100%;
        
    }

    .experience-list-item-company {
        max-width: 205px;
        width: 100%;text-align: center;
        display: block;
    }

    .experience-list-item-description {
        max-width: 492px;
        width: 100%;
        display: block;
    }
}

@media screen and (max-width: 1200px) {
    .experience-list-container {
        font-size: 22px;

        .experience-list-item-arrow {
            max-width: 60px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .experience-list-container {
        font-size: 18px;
        line-height: 29px;

        li {
            min-height: 160px;
        }

        .experience-list-item-description {
            max-width: 380px;
        }

        .experience-list-item-company {
            max-width: 180px;
        }

        .experience-list-item-arrow {
            max-width: 50px;
        }
    }
}

@media screen and (max-width: 640px) {
    .experience-list-container {
        font-size: 12px;
        line-height: 18px;

        li {
            min-height: 100px;
        }
        
        .experience-list-item-years {
            max-width: 130px;
        }

        .experience-list-item-company {
            max-width: 150px;
        }

        .experience-list-item-description {
            max-width: 380px;
        }

        .experience-list-item-arrow {
            max-width: 30px;
        }
    }
}

@media screen and (max-width: 480px) {
    .experience-list-container {
        
        .experience-list-item-years {
            max-width: 85px;
            text-align: right;
        }

        .experience-list-item-company {
            max-width: 120px;
        }

        .experience-list-item-description {
            max-width: 380px;
        }

    }
}