@import '../sharedStyles.scss';

.home-page {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .wrapper {
        max-width: 1500px;
        width: 100%;
        margin: 0 auto;
        padding: 0 50px;
    }

    .canvas-container {
        // position: absolute;
        // width: 100%;
        // display: block;
        // min-height: 123vh;
        // overflow: hidden;
        // pointer-events: none;

        position: absolute;
        width: 100%;
        display: block;
        min-height: 123vh;
        overflow: hidden;
        pointer-events: none;
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    // h2 {
    //     font-size: 80px;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 80px;
    // }

    .blurred-image {
        height: 100vh;
        width: 100vw;
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        background-size: 50%;
        background-origin: padding-box;

        // background-blend-mode: screen;
        // mix-blend-mode: hard-light;

        margin: 0 auto;

        &.blurred-0 {
            background-image: url(../../images/glow-yellow.png);
            background-repeat: no-repeat;
            
            background-position: -300px -20vh;
            transform: translate3d(0, 0, 0);

        }

        &.blurred-1 {
            background-image: url(../../images/glow-blue.png);
            background-repeat: no-repeat;
            
            background-position: 300px -20vh;
            transform: translate3d(0, 0, 0);
        }

        &.blurred-2 {
            background-image: url(../../images/glow-green.png);
            background-repeat: no-repeat;

            background-position: 0px 0;
            transform: translate3d(0, 0, 0);
        }
    }
}

@media screen and (max-width: 1024px) {
    .home-page {
    
        .wrapper {
            padding: 0 20px;
        }
    }
}

@media screen and (max-width: 768px) {

    .canvas-container {
        canvas {
            left: 0 !important;
            transform: translateX(-50%) translate3d(0px, 0px, 0px)  scale(2) !important;
        }
    }


}

@media screen and (max-width: 640px) {
    .home-page {
    
        .wrapper {
            padding: 0 10px;
        }
    }
}