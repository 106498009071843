.work-item-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;

    color: #000000;

    margin-bottom: 160px;

    &.content-float-right {
        flex-direction: row;

        .work-item-info {
            padding-left: 40px;
        }
    }

    &.content-float-left {
        flex-direction: row-reverse;

        .work-item-info {
            padding-right: 40px;
        }

        .work-item-image {
            text-align: right;
        }
    }

    .work-item-image-hovered {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 0; bottom: 0;
        left: 0; right: 0;

        background: #00000059;

        color: #ffffff;
        font-size: 25px;
        user-select: none;

        cursor: pointer;

        opacity: 0;

        transition: all .2s ease-in-out;

        box-shadow: 0px 0px 7px #525252;
    }

    .item-image-effects-container {
        position: relative;
        display: inline-block;
    }

    span {
        display: block;
    }

    .work-item-image {
        display: block;

        flex: 2;

        position: relative;

        &.loading {
            min-height: 451px;
            width: 100%;
        }
    }

    img {
        transition: all .3s ease-in-out;
        width: 100%;
    }

    .work-item-info {
        display: block;
        flex: 1;
        min-width: 500px;

        a {
            display: block;
        }
    }

    .work-item-image:hover {
        img {
            filter: blur(3px);
            transform: translate3d(0, 0, 0);
        }

        .work-item-image-hovered {
            opacity: 1;
        }
    }

    .work-item-image:hover + .work-item-info {
        .work-item-title {
            color: #a0a0a0;
        }
    }

    .work-item-image.hovered {
        img {
            filter: blur(3px);
            transform: translate3d(0, 0, 0);
        }

        .work-item-image-hovered {
            opacity: 1;
        }
    }

    .work-item-title {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 21px;
        transition: all .2s ease-in-out;

        cursor: pointer;
        user-select: none;

        &:hover {
            color: #a0a0a0;
        }
    }

    .work-item-type {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 21px;
        color: rgba(0, 0, 0, 0.5);
    }

    .work-item-description {
        font-size: 30px;
        line-height: 45px;
    }
}

@media screen and (max-width: 1200px) {
    .work-item-container {

        margin-bottom: 100px;

        .work-item-image {
            &.loading {
                min-height: 251px;
                width: 100%;
            }
        }

        .work-item-info {
            min-width: 400px;
        }

        .work-item-title {
            font-size: 35px;
            margin-bottom: 0px;
        }

        .work-item-type {
            font-size: 15px;
        }

        .work-item-description {
            font-size: 18px;
            line-height: 28px;
        }
    }
}

@media screen and (max-width: 1024px) {
    .work-item-container {

        margin-bottom: 100px;

        .work-item-image {
            &.loading {
                min-height: 251px;
                width: 100%;
            }
        }

        .work-item-info {
            min-width: 300px;
        }

        .work-item-title {
            font-size: 30px;
            line-height: 30px;
        }

        .work-item-type {
            font-size: 12px;
            margin-bottom: 0;
        }

        .work-item-description {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media screen and (max-width: 640px) {
    .work-item-container {

        max-width: 500px;
        margin: 0 auto;

        &.content-float-right {
            flex-direction: column;
    
            .work-item-info {
                padding-left: 0px;
            }

            .work-item-image {
                text-align: center;
            }
        }
    
        &.content-float-left {
            flex-direction: column;
    
            .work-item-info {
                padding-right: 0px;
            }
    
            .work-item-image {
                text-align: center;
            }
        }


        flex-wrap: wrap;
        margin-bottom: 50px;

        

        .work-item-image {
            &.loading {
                min-height: 250px;
                width: 100%;
            }

            margin-bottom: 10px;
        }

        .work-item-info {
            min-width: 1px;
        }

        .work-item-title {
            font-size: 30px;
            line-height: 30px;
        }

        .work-item-type {
            font-size: 12px;
            margin-bottom: 0;
        }

        .work-item-description {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@media screen and (max-width: 480px) {
    .work-item-container {
        .work-item-title {
            font-size: 24px;
        }
        .work-item-description {
            font-size: 14px;
            line-height: 24px;
        }
    }
}