.intro-section {
    height: 100vh;
    width: 100%;

    position: relative;

    // &.not-animated {
    //     .intro-email-button {
    //         opacity: 0;
    //     }

    //     .intro-scroll-to {
    //         opacity: 0;
    //     }
    // }

    &.animated {

    }


    .blurred-container {
        position: relative;
        width: 100vw;
        overflow: hidden;
        min-height: 150%;
        z-index: -1;

        .blurred-1 {
            position: absolute;
            width: 615px;
            height: 874px;
            left: 63px;
            top: 0px;

            background: #FFB178;
            filter: blur(384px);
            transform: translate3d(0, 0, 0);
        }

        .blurred-2 {
            position: absolute;
            width: 809px;
            height: 484px;
            left: 650px;
            top: 119px;

            background: #989CFF;
            filter: blur(364px);
            transform: translate3d(0, 0, 0);
        }

        .blurred-3 {
            position: absolute;
            width: 950px;
            height: 355px;
            left: 707px;
            top: 597px;

            background: #CCFDB5;
            filter: blur(234px);
            transform: translate3d(0, 0, 0);
        }

    }

    .intro-description {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 51px;

        position: absolute;
        margin: 0 auto;

        left: 0;
        right: 0;

        top: 50%;
        transform: translateY(-50%);

        max-width: 1500px;
        padding: 0 50px;
        width: 100%;

        margin-bottom: 20px;

        user-select: none;
    }

    .intro-email-button {
        width: 223px;
        height: 78px;

        border: 1px solid #000000;
        border-radius: 50px;

        cursor: pointer;
        user-select: none;

        // position: absolute;
        z-index: 1;
        top: 0;
        top: 60%;
        //transform: translateY(-50%);

        margin-top: 20px;

        text-align: center;
        padding: 21px 0;

        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;

        transition: all .3s ease-in-out;

        &:hover {
            background: #000;
            color: #fff;
        }
    }

    .intro-scroll-to {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 55px;
        margin: 0 auto;

        max-width: 100px;
        text-align: center;

        transition: all .3s ease-in-out;
    }

    .scroll-to-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height */

        text-decoration-line: underline;
        display: inline-block;

        cursor: pointer;
        user-select: none;
    }

    .scroll-to-arrow {
        background-image: url(../../../../images/arrow-scroll-to.png);
        background-repeat: no-repeat;
        background-size: contain;
        width: 23px;
        height: 35px;
        display: inline-block;

        transform: translateY(20px);

        animation: arrowMovement 1.2s ease-in-out 0s infinite alternate;
    }

    @keyframes arrowMovement {
        0% {
            transform: translateY(20px);
        }

        50% {
            transform: translateY(30px);
        }

        100% {
            transform: translateY(20px);
        }
    }
}




@media screen and (max-width: 768px) {
    .intro-section {
        .intro-email-button {
            top: 66%;
            left: 5%;
        }
    }
}

@media screen and (max-width: 640px) {
    .intro-section .intro-description {
        font-size: 27px;
        line-height: 37px;
        padding: 0 10px;
    }
}